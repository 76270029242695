<template>
  <section class="card">
    <div class="card-content">
      <div class="columns is-multiline">
        <div class="column is-12">
          <p class="font_18 has_lightgrey has-text-weight-semibold">
            Información general del interesado
          </p>
        </div>
        <div class="column is-12">
          <div class="columns is-multiline">
            <div class="column is-12">
              <p class="font_15 has_gray has-text-weight-bold">
                {{ applicantName }}
              </p>
            </div>

            <div class="column is-5">
              <p class="font_15 has_gray">
                Balance total del usuario:
              </p>
            </div>
            <div class="column is-7">
              <p class="font_15 has_gray has-text-weight-semibold">
                {{ formatPrice(balanceUser) || 0 }}
              </p>
            </div>

            <div class="column is-5">
              <p class="font_15 has_gray">
                Celular/Whatsapp:
              </p>
            </div>
            <div class="column is-7">
              <p class="font_15 has_gray has-text-weight-semibold">
                {{ applicantPhone }}
              </p>
            </div>

            <div class="column is-5">
              <p class="font_15 has_gray">
                Correo electrónico:
              </p>
            </div>
            <div class="column is-7">
              <p class="font_15 has_gray has-text-weight-semibold">
                {{ applicantEmail }}
              </p>
            </div>

            <div class="column is-5">
              <p class="font_15 has_gray">
                Tipo de persona:
              </p>
            </div>
            <div class="column is-7">
              <p class="font_15 has_gray has-text-weight-semibold">
                {{ applicantPersonTypeName }}
              </p>
            </div>

            <div class="column is-5">
              <p class="font_15 has_gray">
                Razón Social:
              </p>
            </div>
            <div class="column is-7">
              <p class="font_15 has_gray has-text-weight-semibold">
                {{ applicantBusinessName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { formatPrice } from '@/helpers/formats'

export default {
  name: 'TransactionInterestedInformation',
  data () {
    return {
      applicantName: null,
      applicantPhone: null,
      applicantEmail: null,
      applicantPersonTypeName: null,
      applicantBusinessName: null
    }
  },
  props: {
    dataDetail: {
      type: Object,
      default: () => {}
    },
    balanceUser: {
      type: [Number, String],
      default: null
    }
  },
  methods: {
    formatPrice,
    spreadData () {
      this.applicantName = `${this.dataDetail.user_name} ${this.dataDetail.user_last_name || ''} ${this.dataDetail.user_second_last_name || ''}`
      this.applicantPhone = this.dataDetail.user_phone
      this.applicantEmail = this.dataDetail.user_email
      this.applicantPersonTypeName = this.dataDetail.user_person_type_name
      this.applicantBusinessName = this.dataDetail.user_business_name || 'N/A'
    }
  },
  beforeMount () {
    this.spreadData()
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 5px;
    box-shadow: 0 3px 45px 0 rgba(188, 188, 222, 0.27);
    background-color: #fff;
  }
</style>
